import Image from 'next/image'
import { Box, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { ProductT } from '../../../../clients/cms'
import { imageLoader } from '../../../../utils/image-loader'
import styles from './card-image.module.css'

type CardImageProps = {
  product: ProductT
  onClick: () => void
}

export const CardImageAnimation: React.FC<CardImageProps> = ({
  product,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      className={styles['card-image-box']}
      height={160}
      width={245}
      position="relative"
      overflow="hidden"
      borderRadius="10px"
      cursor="pointer"
    >
      <Image
        loader={imageLoader}
        src={product.image.asset.url}
        alt={`${product.name}`}
        layout="fill"
        objectFit="cover"
      />
      <Box
        className={styles['card-image-hidden']}
        background="rgba(0,0,0,.3)"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Image
          className={styles['card-image-opacity']}
          src="/static/icons/icon-lock-round-w.svg"
          alt="Lock"
          width={32}
          height={32}
        />
        <Text
          className={styles['card-image-translate']}
          color="white"
          textAlign="center"
          fontWeight="bold"
          marginTop="2"
        >
          Apply Now
        </Text>
      </Box>
    </Button>
  )
}
