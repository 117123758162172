import React from 'react'

import { Link } from '@chakra-ui/react'
import { PRIMARY_LINK_COLOR } from '../colors'

type SanityLinkSerializerProps = {
  children: React.ReactElement
  mark: {
    href: string
  }
}

/**
 *
 * Sanity link serializer
 * https://github.com/sanity-io/block-content-to-react#proptypes
 */
export const SanityLinkSerializer = (
  props: SanityLinkSerializerProps,
): JSX.Element => (
  <Link
    href={props.mark.href}
    isExternal
    style={{
      color: PRIMARY_LINK_COLOR,
    }}
  >
    {props.children}
  </Link>
)
