import React from 'react'
import { Box, Divider, Text } from '@chakra-ui/layout'
import { useBreakpointValue } from '@chakra-ui/react'
import { generateYear } from '../utils/generate-year'
import { getMonthName } from '../utils/get-month-name'
import {
  PRIMARY_BLUE_COLOR,
  PRIMARY_COLOR,
  SECONDARY_TEXT_COLOR,
} from './colors'

const generateLastModified = (lastModified: string): string => {
  const date = new Date(lastModified)
  const monthName = getMonthName(date)
  const dayName = date.getDay()
  const year = date.getFullYear()

  return `Last Modified: ${monthName} ${dayName}, ${year}`
}

type TitleLegalPageProps = {
  title: string
  lastModified?: string
}
export const TitleLegalPage: React.FC<TitleLegalPageProps> = ({
  title,
  lastModified,
}) => {
  const showDeskTopView = useBreakpointValue({ md: true })
  const lastModifiedText = lastModified
    ? generateLastModified(lastModified)
    : `Last Modified: October 5, ${generateYear(10, 4)}`

  return (
    <Box textAlign="center">
      <Text
        as="h1"
        fontSize={showDeskTopView ? '5xl' : '3xl'}
        color={PRIMARY_COLOR}
        mb="4"
      >
        {title}
      </Text>
      <Box margin="auto" width="10rem">
        <Divider style={{ border: `3px solid ${PRIMARY_BLUE_COLOR}` }} />
      </Box>
      <Text as="h2" fontSize="xl" color={SECONDARY_TEXT_COLOR} mt="4">
        {lastModifiedText}
      </Text>
    </Box>
  )
}
