/**
 * A custom function used to resolve URLs
 * See https://nextjs.org/docs/api-reference/next/image#loader
 */
export const imageLoader = ({
  src,
  width,
}: {
  src: string
  width: number
}): string => `${src}?w=${width}`
