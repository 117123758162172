import React from 'react'
import Image from 'next/image'
import { Box, Container } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
// @ts-expect-error Could not find a declaration file for module
import ColorContrastChecker from 'color-contrast-checker'
import { imageLoader } from '../../../utils/image-loader'
import { ProductDetails } from './product-details'
import { ProductDetailsButton } from './product-details-button'
import { ProductItemProps } from './type'
import { ProductInformation } from './product-information'
import { ProductRatesFees } from './product-rates-fees'

interface CCC {
  isLevelAA: (arg1: string, arg2: string, arg3: number) => boolean
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const ccc = new ColorContrastChecker() as CCC

export const MobileView: React.FC<ProductItemProps> = ({
  partnerColor,
  isCollapsed,
  product,
  handleClick,
  handleApplyNow,
}) => {
  const black = '#000000'
  const white = '#FFFFFF'
  const textColor = ccc.isLevelAA(partnerColor, black, 18) ? black : white
  const partnerColorIsWhite = partnerColor.toLowerCase() === white.toLowerCase()

  return (
    <Container
      data-cy="product-item"
      maxWidth="700px"
      centerContent
      borderWidth="1px"
      borderRadius="lg"
      my="8"
      px="0"
      pt="8"
      textAlign="center"
      borderColor="gray.100"
      boxShadow="md"
      _hover={{ boxShadow: 'xl' }}
    >
      <Button
        onClick={handleApplyNow}
        position="relative"
        width="100%"
        height="150px"
        style={{ background: 'none' }}
      >
        <Image
          loader={imageLoader}
          src={product.image.asset.url}
          alt={`${product.name}`}
          layout="fill"
          objectFit="contain"
        />
      </Button>
      <Box mt="4" px={{ md: '10' }}>
        <Box
          as="h2"
          fontSize="x-large"
          fontWeight="bold"
          color="blue.700"
          my="2"
        >
          {product.name}
        </Box>
        <Button
          border={partnerColorIsWhite ? '1px' : '0px'}
          borderColor={partnerColorIsWhite ? 'gray.300' : ''}
          color={textColor}
          bgColor={partnerColor}
          data-cy="product-apply-now-button"
          _hover={{ boxShadow: 'xl' }}
          fontSize="18px"
          fontWeight="semibold"
          size="lg"
          width="75%"
          marginBottom="4"
          onClick={handleApplyNow}
        >
          Apply Now
        </Button>
        <Box mb="3">
          <ProductRatesFees product={product} />
        </Box>
        <ProductInformation product={product} />

        <ProductDetails
          showDetails={!isCollapsed}
          marketingBullets={product.marketingBullets}
        />

        <ProductDetailsButton
          handleClick={handleClick}
          isCollapsed={isCollapsed}
        />
      </Box>
    </Container>
  )
}
