import { Box, Text } from '@chakra-ui/layout'
import {
  RenderBlockContent,
  RenderBlockContentProps,
} from '../../sanity/render-block-content'

export type ProductInformationItemProps = {
  text: RenderBlockContentProps['blocks']
  label: string
  isEven: boolean
}

export const ProductInformationItem: React.FC<ProductInformationItemProps> = ({
  text,
  label,
  isEven,
}) => (
  <Box w={{ md: '100%' }}>
    <Box
      d={{ base: 'flex', md: 'block' }}
      alignItems="baseline"
      textAlign="left"
      py={{ base: '4', md: '0' }}
      px={{ base: '6', md: '0' }}
      width={{ base: '100%', md: 'initial' }}
      bg={{
        base: isEven ? 'blackAlpha.100' : '',
        md: 'white',
      }}
      mr={{
        md: '2',
      }}
    >
      <Text
        as="h3"
        width="10em"
        fontWeight="bold"
        flex={{
          md: '1',
        }}
        fontSize={{ base: 'medium', md: '16px' }}
        color={{ base: 'brand.200', md: 'brand.200' }}
      >
        {label}
      </Text>
      <RenderBlockContent blocks={text} />
    </Box>
  </Box>
)
