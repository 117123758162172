import React from 'react'
import { Box, Container, Flex } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
// @ts-expect-error Could not find a declaration file for module
import ColorContrastChecker from 'color-contrast-checker'
import { supify } from '../../../utils/supify'
import { ProductDetails } from './product-details'
import { ProductDetailsButton } from './product-details-button'
import { ProductItemProps } from './type'
import { ProductInformation } from './product-information'
import { CardImageAnimation } from './card-image'
import { ProductRatesFees } from './product-rates-fees'

interface CCC {
  isLevelAA: (arg1: string, arg2: string, arg3: number) => boolean
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const ccc = new ColorContrastChecker() as CCC

export const DesktopView: React.FC<ProductItemProps> = ({
  partnerColor,
  isCollapsed,
  handleClick,
  product,
  handleApplyNow,
}) => {
  const black = '#000000'
  const white = '#FFFFFF'
  const textColor = ccc.isLevelAA(partnerColor, black, 18) ? black : white
  const partnerColorIsWhite = partnerColor.toLowerCase() === white.toLowerCase()

  return (
    <Container
      data-cy="product-item"
      maxW="100%"
      borderWidth="1px"
      borderRadius="lg"
      mb="8"
      px="0"
      pt="6"
      textAlign="left"
      borderColor="gray.100"
      boxShadow="md"
      _hover={{ boxShadow: 'xl' }}
    >
      <Flex px="8" paddingY={3} paddingBottom={6}>
        <Box position="relative" width="30%">
          <CardImageAnimation onClick={handleApplyNow} product={product} />
          <Box paddingTop={2}>
            <Button
              border={partnerColorIsWhite ? '1px' : '0px'}
              borderColor={partnerColorIsWhite ? 'gray.300' : ''}
              color={textColor}
              bgColor={partnerColor}
              data-cy="product-apply-now-button"
              _hover={{ boxShadow: 'xl' }}
              fontSize="18px"
              fontWeight="bold"
              size="md"
              width="full"
              onClick={handleApplyNow}
            >
              Apply Now
            </Button>
            <Box
              as="p"
              textAlign="center"
              fontWeight="bold"
              fontSize="10px"
              paddingTop={1}
              color="brand.400"
              opacity="50%"
            >
              On Credit Card&apos;s secure site
            </Box>

            <ProductRatesFees product={product} />
          </Box>
        </Box>
        <Box paddingLeft="2rem" width="70%">
          <Flex>
            <Box paddingBottom="1rem">
              <Box
                as="h2"
                lineHeight="38px"
                fontSize="22px"
                fontWeight="bold"
                color="brand.100"
                dangerouslySetInnerHTML={{
                  __html: supify(product.name),
                }}
              ></Box>
            </Box>
          </Flex>
          <ProductInformation product={product} />
        </Box>
      </Flex>
      <ProductDetails
        showDetails={!isCollapsed}
        marketingBullets={product.marketingBullets}
      />
      <ProductDetailsButton
        handleClick={handleClick}
        isCollapsed={isCollapsed}
      />
    </Container>
  )
}
