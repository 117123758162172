export const prepareUrl = (
  url: string,
  newParameters?: Array<{ name: string; value: string }>,
): string => {
  if (!newParameters) return url

  const { searchParams, origin, pathname } = new URL(url)

  const searchParameters = new URLSearchParams(searchParams)
  for (const parameter of newParameters) {
    searchParameters.append(parameter.name, parameter.value)
  }

  const allSearchParameters = searchParameters.toString()

  return `${origin}${pathname}?${allSearchParameters}`
}
