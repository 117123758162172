import React from 'react'
import { Text } from '@chakra-ui/layout'
import { ProductT } from '../../../clients/cms'
import { prepareUrl } from '../../../utils'

type ProductRatesFeesProps = {
  product: ProductT
}

export const ProductRatesFees: React.FC<ProductRatesFeesProps> = ({
  product,
}) => {
  const { ratesAndFees } = product
  if (!ratesAndFees) return <> </>

  const handleOnClick = () => {
    const url = prepareUrl(
      ratesAndFees,
      product.ratesAndFeesLinkParameters?.map((item) => ({
        name: item.name,
        value: item.parameter,
      })),
    )

    window.open(url, '_blank')
  }

  return (
    <Text
      as="a"
      textAlign="center"
      color="#58B9FC"
      fontWeight="medium"
      fontSize="12px"
      mt="2"
      onClick={handleOnClick}
      cursor="pointer"
      display="block"
    >
      Rates & Fees
    </Text>
  )
}
