/* eslint-disable unicorn/no-array-reduce */

/**
 * Helper to add superscript tags to common credit card trademarks, service marks, etc for styling
 */

import { compose, split } from 'lodash/fp'

const MARKS_TO_SUP: Record<string, string> = Object.freeze({
  '&reg;': '&reg;',
  '®': '&reg;',
  '&#8480;': '&#8480;',
  '℠': '&#8480;',
})

export function supify(text?: string) {
  if (!text) return ''
  return Object.keys(MARKS_TO_SUP).reduce(
    (result, k) => splitAndSup(k, MARKS_TO_SUP[k], result),
    text,
  )
}

function splitAndSup(search: string, replace: string, text: string) {
  return text.includes(search)
    ? compose(supButNotTwice(replace, text), split(search))(text)
    : text
}

function supButNotTwice(replace: string, original: string) {
  return (substrs: string[]) =>
    substrs.some((string_) => string_.startsWith('</sup>'))
      ? original
      : substrs.join(`<sup>${replace}</sup>`)
}
