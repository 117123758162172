import { Box, Text } from '@chakra-ui/layout'
import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { DisclosureT } from '../../clients/cms'
import { Popup } from '../popup'

export const AdvertiserDisclosure: React.FC<{ disclosure: DisclosureT }> = ({
  disclosure,
}) => (
  <Box as="div" display="flex" justifyContent="flex-end" mb="1" mt="1">
    <Popup
      trigger={
        <Text
          as="button"
          backgroundColor="transparent"
          border="none"
          color="#21A3FCBF"
          fontSize="14px"
          textAlign="right"
          fontWeight="medium"
          mr="2"
          fontFamily="Poppins"
        >
          Advertiser Disclosure
        </Text>
      }
    >
      <Text
        as="h6"
        fontSize="lg"
        color="#0047AB"
        marginBottom="1"
        fontWeight="bold"
      >
        {disclosure.name}
      </Text>
      <BlockContent blocks={disclosure.content} />
    </Popup>
  </Box>
)
