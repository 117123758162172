/* eslint-disable no-alert */
import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { AppressaLogoBase } from './appressa-logo/appressa-logo-base'

type ScreenBlockProps = {
  children: React.ReactElement
  password: string
  showScreenBlock: boolean
}

export const ScreenBlock: React.FC<ScreenBlockProps> = ({
  children,
  password,
  showScreenBlock,
}) => {
  const [showBlocker, setShowBlocker] = useState(showScreenBlock)

  const handleShowDialog = () => {
    const userPass = prompt('Please enter screen password:', '')

    if (userPass === password) setShowBlocker(false)
  }

  useEffect(() => {
    document.body.style.overflow = showBlocker ? 'hidden' : 'auto'
  }, [showBlocker])

  return (
    <Box>
      {showBlocker ? (
        <Box
          data-cy="offer-screen-block"
          pos="fixed"
          width="100vw"
          height="100vh"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          backgroundColor="white"
          zIndex="100"
          overflow="hidden"
        >
          <AppressaLogoBase />
          <Button
            data-cy="offer-screen-block-button"
            autoFocus={true}
            mt="5"
            onClick={handleShowDialog}
            backgroundColor="#00D512"
            w="200px"
            color="white"
            _hover={{
              backgroundColor: '#00D512',
              opacity: 0.6,
            }}
          >
            Enter
          </Button>
        </Box>
      ) : (
        children
      )}
    </Box>
  )
}
/* eslint-enable no-alert */
