import React from 'react'

type AppressaLogoBaseProps = {
  width?: string
  height?: string
}

export const AppressaLogoBase: React.FC<AppressaLogoBaseProps> = ({
  width,
  height,
}) => (
  <svg
    id="appressa-logo-final"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 480.63 84.25"
    width={width ?? '300'}
    height={height ?? '100'}
    fill="none"
  >
    <g id="appressa-logo">
      <path
        d="M105.56,1.11a28.75,28.75,0,0,1,7.68,1A26.66,26.66,0,0,1,120,5.06a28,28,0,0,1,10.05,10.7,30.81,30.81,0,0,1,2.76,7.38,37.32,37.32,0,0,1-.41,18.43,27.17,27.17,0,0,1-10,14.53,25.82,25.82,0,0,1-7.85,4,30.21,30.21,0,0,1-9.17,1.38q-11.84,0-18.51-8.14h-.18V84.25H73.33V2H86.75V9.08H87Q93.79,1.1,105.56,1.11Zm-2.23,48.16a16,16,0,0,0,12.13-5q4.7-4.95,4.69-12.74,0-8.26-4.83-13.27a16.07,16.07,0,0,0-12-5A15.86,15.86,0,0,0,91,18.51a18.64,18.64,0,0,0-4.78,13.07q0,7.85,4.93,12.77A16.54,16.54,0,0,0,103.33,49.27Z"
        fill="#000a4c"
      />
      <path
        d="M176.43,1.11a28.69,28.69,0,0,1,7.68,1,26.88,26.88,0,0,1,6.8,2.93,28.12,28.12,0,0,1,10,10.7,30.4,30.4,0,0,1,2.76,7.38,36.13,36.13,0,0,1,1,8.38,35.68,35.68,0,0,1-1.37,10.05,27.23,27.23,0,0,1-10,14.53,25.94,25.94,0,0,1-7.85,4,30.3,30.3,0,0,1-9.17,1.38q-11.85,0-18.52-8.14h-.18V84.25H144.21V2h13.41V9.08h.24Q164.65,1.1,176.43,1.11Zm-2.22,48.16a15.93,15.93,0,0,0,12.12-5Q191,39.37,191,31.58q0-8.26-4.83-13.27a17,17,0,0,0-24.32.2,18.63,18.63,0,0,0-4.77,13.07q0,7.85,4.92,12.77A16.55,16.55,0,0,0,174.21,49.27Z"
        fill="#000a4c"
      />
      <path
        d="M28.56,61.46,60,38.72V23.89L28.49,1.11h-.25A27.65,27.65,0,0,0,17.05,3.39a27.22,27.22,0,0,0-9,6.27,28.94,28.94,0,0,0-5.92,9.67A33.81,33.81,0,0,0,0,31.52,35.68,35.68,0,0,0,1.38,41.57,27.55,27.55,0,0,0,5.3,49.89a28.17,28.17,0,0,0,6,6.24,25.17,25.17,0,0,0,7.85,4,30.88,30.88,0,0,0,9.17,1.35M42.28,44.32a15.89,15.89,0,0,1-11.81,5,16,16,0,0,1-12.13-5q-4.69-4.95-4.69-12.74A21.23,21.23,0,0,1,15,24a15.82,15.82,0,0,1,9-9.41,17,17,0,0,1,6.59-1.28,15.26,15.26,0,0,1,11.86,5.24,18.76,18.76,0,0,1,4.72,13A17.48,17.48,0,0,1,42.28,44.32Z"
        fill="#000a4c"
      />
      <path
        d="M341.1,61.3q-14,0-23.67-8.56l6.8-10.08a23.63,23.63,0,0,0,7.58,5.25,24.14,24.14,0,0,0,9.29,1.67,10.09,10.09,0,0,0,6-1.59,4.89,4.89,0,0,0,2.19-4.16q0-2.1-2.49-3.48a65.06,65.06,0,0,0-9.11-3.66c-1.75-.59-3.32-1.18-4.68-1.76a44,44,0,0,1-4.19-2.08,17.8,17.8,0,0,1-3.67-2.67,21,21,0,0,1-2.78-3.31,13.93,13.93,0,0,1-1.9-4.19,19.36,19.36,0,0,1-.65-5.15,14.67,14.67,0,0,1,5.83-11.87Q331.5,1,340.46.94A32.3,32.3,0,0,1,361.2,8.27l-6.74,10.54a25.11,25.11,0,0,0-4.89-3.54,22.54,22.54,0,0,0-5-2.08,18.44,18.44,0,0,0-4.81-.64,8,8,0,0,0-4.36,1.28,4.17,4.17,0,0,0-2,3.75,3.8,3.8,0,0,0,1.23,2.67,12.6,12.6,0,0,0,3.1,2.26,52.16,52.16,0,0,0,5,2.22q9.31,3.69,11.61,4.87,5.79,3.1,7.61,7.55A17.68,17.68,0,0,1,363,43.54a17.6,17.6,0,0,1-1.35,6.94,16.21,16.21,0,0,1-4,5.63,18.45,18.45,0,0,1-6.86,3.81A31.09,31.09,0,0,1,341.1,61.3Z"
        fill="#000a4c"
      />
      <path
        d="M393.35,61.3q-14,0-23.67-8.56l6.79-10.08a23.77,23.77,0,0,0,7.59,5.25,24.1,24.1,0,0,0,9.29,1.67,10.09,10.09,0,0,0,6-1.59,4.89,4.89,0,0,0,2.19-4.16q0-2.1-2.49-3.48A65.6,65.6,0,0,0,390,36.69q-2.64-.88-4.69-1.76a46.47,46.47,0,0,1-4.19-2.08,18,18,0,0,1-3.66-2.67,21,21,0,0,1-2.78-3.31,14,14,0,0,1-1.91-4.19,19.37,19.37,0,0,1-.64-5.15,14.65,14.65,0,0,1,5.83-11.87Q383.75,1,392.7.94a32.33,32.33,0,0,1,20.75,7.33l-6.74,10.54a25.42,25.42,0,0,0-4.89-3.54,22.54,22.54,0,0,0-5-2.08,18.55,18.55,0,0,0-4.81-.64,7.92,7.92,0,0,0-4.36,1.28,4.18,4.18,0,0,0-2,3.75,3.81,3.81,0,0,0,1.24,2.67,12.6,12.6,0,0,0,3.1,2.26,52.16,52.16,0,0,0,5,2.22q9.31,3.69,11.6,4.87,5.81,3.1,7.62,7.55a17.91,17.91,0,0,1,1.05,6.39,17.6,17.6,0,0,1-1.34,6.94,16.11,16.11,0,0,1-4,5.63A18.41,18.41,0,0,1,403,59.92,31.13,31.13,0,0,1,393.35,61.3Z"
        fill="#000a4c"
      />
      <path
        d="M467.21,1.82h13.42V60.36H467.8V52.51h-.18Q460.95,61.3,449,61.3A30.88,30.88,0,0,1,439.82,60,25.19,25.19,0,0,1,432,56,27.46,27.46,0,0,1,422,41.4a35.58,35.58,0,0,1-1.38-10,34,34,0,0,1,2.17-12.18,28.84,28.84,0,0,1,5.92-9.67,27,27,0,0,1,9-6.27A27.65,27.65,0,0,1,448.87.94Q460.3.94,467,8.85h.23ZM451.1,49.11a15.87,15.87,0,0,0,11.81-5,17.49,17.49,0,0,0,4.83-12.75,18.77,18.77,0,0,0-4.71-13,15.28,15.28,0,0,0-11.87-5.25,16.87,16.87,0,0,0-6.59,1.29,15.84,15.84,0,0,0-9,9.4,21.31,21.31,0,0,0-1.32,7.59q0,7.8,4.69,12.75A16,16,0,0,0,451.1,49.11Z"
        fill="#000a4c"
      />
      <path
        d="M227.52,2.31a17.89,17.89,0,0,0-6.33,3.36,17.08,17.08,0,0,0-4.45,5.77,18.69,18.69,0,0,0-1.67,8.14V60.52h12.21V20.46c0-2.35.74-4.08,2.2-5.15a8.74,8.74,0,0,1,5.42-1.57h12V1.22H235A26.22,26.22,0,0,0,227.52,2.31Z"
        fill="#000a4c"
      />
      <path
        d="M302.21,7.92a25.88,25.88,0,0,0-9.41-6A33.45,33.45,0,0,0,282.18,0h-1a33.67,33.67,0,0,0-10.61,1.94,25.88,25.88,0,0,0-9.41,6,29.44,29.44,0,0,0-6.33,9.86,36.56,36.56,0,0,0-2.41,13.61,33.59,33.59,0,0,0,2.49,13.43,27.4,27.4,0,0,0,6.57,9.42A26.61,26.61,0,0,0,271,59.79a34.73,34.73,0,0,0,10.2,1.81h1a34.74,34.74,0,0,0,10.21-1.81,26.49,26.49,0,0,0,8.94-5.12l.25-.19.29-.24a27.27,27.27,0,0,0,6.47-9.11H292.75l-.1.09a4.18,4.18,0,0,1-.44.4,15.9,15.9,0,0,1-4.71,2.58,18.2,18.2,0,0,1-11.62,0,16.19,16.19,0,0,1-4.71-2.6,14.34,14.34,0,0,1-3.23-3.67A21.77,21.77,0,0,1,266,37.82l-.12-.35h44.61a39.86,39.86,0,0,0,.46-6.08,36.13,36.13,0,0,0-2.37-13.61A28.87,28.87,0,0,0,302.21,7.92Zm-36.44,19,.07-.33a24.67,24.67,0,0,1,1.81-5.42,16.21,16.21,0,0,1,3.16-4.52,14.33,14.33,0,0,1,4.8-3.11,16.6,16.6,0,0,1,6-1.13h0a16.49,16.49,0,0,1,6.05,1.14,14.29,14.29,0,0,1,4.79,3.11,16.21,16.21,0,0,1,3.16,4.52,25.2,25.2,0,0,1,1.81,5.42l.07.33Z"
        fill="#000a4c"
      />
      <path
        d="M46.58,9h-.23Q39.74,1.19,28.49,1.11L60,23.89V2H46.58Z"
        fill="#29aae2"
      />
      <path
        d="M60,60.52V38.72L28.56,61.46Q40.37,61.38,47,52.67h.18v7.85Z"
        fill="#00d512"
      />
    </g>
  </svg>
)
