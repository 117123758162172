import { ProductT } from '../clients/cms'
import { handleError } from './handle-error'

const URL = '/api/analytics/product-clicked'

type HandleProductClickedInput = {
  partner: string
  product: ProductT
}

type PrepareBody = {
  partner: string
  product: {
    id: string
    name: string
  }
}

export function handleProductClicked(input: HandleProductClickedInput) {
  fetch(URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(prepareBody(input)),
  }).catch(handleError)
}

function prepareBody(input: HandleProductClickedInput): PrepareBody {
  return {
    partner: input.partner,
    product: {
      id: input.product.slug.current,
      name: input.product.name,
    },
  }
}
