import * as React from 'react'
import Image from 'next/image'
import { Box } from '@chakra-ui/react'
import { imageLoader } from '../../utils/image-loader'

type HeaderProps = {
  backgroundColor: string
  foregroundColor?: string
  logoSrc: string
  name: string
}

const DEFAULT_HEADER_PROPS = Object.freeze({
  foregroundColor: 'white',
})

export function OfferHeader(props: HeaderProps) {
  const mergedProps = { ...DEFAULT_HEADER_PROPS, ...props }

  return (
    <Box
      data-cy="header"
      as="header"
      bg={mergedProps.backgroundColor}
      color={mergedProps.foregroundColor}
      h={24}
    >
      <Box w={[32, 40]} h={20} my={2} pos="relative">
        <Image
          loader={imageLoader}
          src={props.logoSrc}
          alt={`${props.name}-logo`}
          layout="fill"
          objectFit="contain"
        />
      </Box>
    </Box>
  )
}
