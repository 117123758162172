import * as z from 'zod'

interface markDefs {
  [key: string]: any
  _type?: string
  _key?: string
}
interface PortableTextBlock {
  _type: string
  _key: string
  children?: PortableTextBlock[]
  style?: string
  text?: string
  listItem?: string
  level?: number
  marks?: any[]
  markDefs?: markDefs[]
}

const Block: z.ZodSchema<PortableTextBlock> = z.lazy(() =>
  z
    .object({
      _type: z.string(),
      _key: z.string(),
      children: z.array(Block).optional(),
      style: z.string().optional(),
      text: z.string().optional(),
      listItem: z.string().optional(),
      level: z.number().optional(),
      marks: z.array(z.unknown()).optional(),
      markDefs: z
        .array(
          z
            .object({
              _type: z.string().optional(),
              _key: z.string().optional(),
            })
            .nonstrict(),
        )
        .optional(),
    })
    .nonstrict(),
)

export default Block
