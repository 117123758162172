export const FOOTER_ITEMS: Array<{ text: string; href: string }> = [
  // {
  //   text: 'Terms and Conditions',
  //   href: '/terms-conditions',
  // },
  // {
  //   text: 'Privacy Policy',
  //   href: '/privacy',
  // },
]
