import { useBreakpointValue } from '@chakra-ui/media-query'
import React from 'react'
import RJsPopup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

type PopupProps = {
  children: React.ReactNode
  trigger: JSX.Element
}

export const Popup: React.FC<PopupProps> = ({ children, trigger }) => {
  const showDeskTopView = useBreakpointValue({ md: true })

  return (
    <div suppressHydrationWarning={true}>
      {process.browser && (
        <RJsPopup
          contentStyle={{
            width: 485,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
            maxWidth: showDeskTopView ? '100%' : '90%',
            borderRadius: 10,
          }}
          trigger={trigger}
          on="click"
          position="bottom right"
        >
          {children}
        </RJsPopup>
      )}
    </div>
  )
}
