import { Box } from '@chakra-ui/layout'
import { ProductT } from '../../../clients/cms'
import {
  ProductInformationItem,
  ProductInformationItemProps,
} from './product-information-item'

type ProductInformationProps = {
  product: ProductT
}

export const ProductInformation: React.FC<ProductInformationProps> = ({
  product,
}) => {
  const items: Array<Omit<ProductInformationItemProps, 'isEven'>> =
    product.topFeatures?.map((feature) => ({
      label: feature.Title,
      text: feature.Description,
    })) ?? []

  return (
    <Box
      borderTopColor="gray.200"
      borderTopWidth="1px"
      borderBottomColor="gray.200"
      borderBottomWidth="1px"
      border={{
        md: 'none',
      }}
      d={{
        md: 'grid',
      }}
      mb={{
        md: 4,
      }}
      gridGap={{
        md: 1,
      }}
      gridTemplateColumns={{
        md: 'repeat(3, 1fr)',
      }}
    >
      {items.map((item, index) => {
        const isEven = index % 2 === 0
        return (
          <ProductInformationItem
            key={item.label}
            label={item.label}
            text={item.text}
            isEven={isEven}
          />
        )
      })}
    </Box>
  )
}
