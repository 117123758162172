import { Box, Text, ListItem, UnorderedList } from '@chakra-ui/layout'
import BlockContent from '@sanity/block-content-to-react'
import { ProductT } from '../../../clients/cms'
import { ColorSerializer } from '../../sanity/sanity-text-with-color/color-serializer'
import styles from './product-details.module.css'

type ProductDetailsProps = {
  marketingBullets: ProductT['marketingBullets']
  showDetails: boolean
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  marketingBullets,
  showDetails,
}) => {
  const list = (props: { children: React.ReactElement }) => (
    <Box paddingTop={2}>
      <UnorderedList paddingY={0.5}>{props.children}</UnorderedList>
    </Box>
  )

  const listItem = (props: { children: React.ReactElement }) => (
    <ListItem color="brand.300" fontSize="16px" marginBottom={2}>
      {props.children}
    </ListItem>
  )

  return (
    <Box
      className={`
       ${styles['product-details-animation']}
       ${showDetails ? '' : styles.hide}
     `}
      position="relative"
      data-cy="product-details-section"
      p={{ base: '6', md: '0' }}
      px={{ md: '8' }}
      mb={{
        base: '2',
        md: '4',
      }}
    >
      <Text
        as="h3"
        pb="1"
        fontWeight="semibold"
        fontSize={{
          base: 'medium',
          md: '14px',
        }}
        color={{
          md: 'brand.200',
        }}
        textTransform={{
          base: 'capitalize',
          md: 'uppercase',
        }}
      >
        Product Details
      </Text>
      <hr />
      <Box
        mt="4"
        px={{
          md: '2',
        }}
        fontSize="medium"
        textAlign="left"
      >
        <BlockContent
          blocks={marketingBullets}
          serializers={{
            list,
            listItem,
            marks: {
              color: ColorSerializer,
              superscript: ({ children }: { children: React.ReactElement }) => {
                return <sup>{children}</sup>
              },
            },
          }}
        />
      </Box>
    </Box>
  )
}
