import { Box, Stack } from '@chakra-ui/layout'
import React from 'react'
import { AppressaLogoLight } from '../appressa-logo/appressa-logo-light'

const currentYear = new Date().getFullYear()

const FooterAppressaLogo: React.FC = () => (
  <Box
    as="li"
    listStyleType="none"
    marginRight={{
      base: 0,
      lg: 'auto',
    }}
    display="flex"
    alignItems={{
      base: 'center',
      lg: 'flex-end',
    }}
    flexDirection={{
      base: 'column',
      lg: 'row',
    }}
  >
    <Stack spacing=".5" marginBottom="-15px">
      <Box
        as="span"
        color="white"
        opacity={0.5}
        fontSize="12px"
        fontFamily="Poppins"
      >
        powered by
      </Box>
      <AppressaLogoLight />
    </Stack>
    <Box
      color="white"
      fontSize="large"
      opacity={0.5}
      as="p"
      marginLeft={{
        base: 0,
        lg: 4,
      }}
      marginBottom={{
        base: 4,
        lg: '-1.5',
      }}
      marginTop={{
        base: 2,
        lg: 0,
      }}
    >
      © {currentYear} Appressa LLC
    </Box>
  </Box>
)

export default FooterAppressaLogo
