import React from 'react'

import { UnorderedList } from '@chakra-ui/react'

type SanityListSerializerProps = {
  children: React.ReactElement
  spacing?: number
}

/**
 *
 * Sanity list serializer
 * https://github.com/sanity-io/block-content-to-react#proptypes
 */
export const SanityListSerializer = (
  props: SanityListSerializerProps,
): JSX.Element => (
  <UnorderedList spacing={props.spacing ?? 3}>{props.children}</UnorderedList>
)
