import { datatype } from 'faker'
import { z } from 'zod'

import Block from '../../../clients/cms/sanity/schema/block'

export const productDetailsPhoneNumber = (
  phoneNumber: string,
  phoneNumberVerbiage?: string,
): z.infer<typeof Block> => {
  return {
    _type: 'block',
    _key: datatype.uuid(),
    children: [
      {
        marks: [],
        text: `Click `,
        _key: datatype.uuid(),
        _type: 'span',
      },
      {
        marks: ['LINK', 'BLUE_COLOR'],
        text: `"APPLY NOW" `,
        _key: datatype.uuid(),
        _type: 'span',
      },
      {
        marks: [],
        text: `to apply online or call `,
        _key: datatype.uuid(),
        _type: 'span',
      },
      {
        marks: ['LINK', 'BLUE_COLOR'],
        text: `${phoneNumber}.`,
        _key: datatype.uuid(),
        _type: 'span',
      },
      {
        marks: [],
        text: `${phoneNumberVerbiage ?? ''}`,
        _key: datatype.uuid(),
        _type: 'span',
      },
    ],
    style: 'normal',
    listItem: 'bullet',
    level: 1,
    markDefs: [
      {
        _type: 'link',
        _key: 'LINK',
        href: `tel:${phoneNumber.replace(/\D/g, '')}`,
      },
      {
        alpha: 1,
        hex: '#21A3FCBF',
        _key: 'BLUE_COLOR',
        _type: 'color',
      },
    ],
  }
}
