import * as React from 'react'
import { Box } from '@chakra-ui/react'
import { AppressaLogoBase } from '../appressa-logo/appressa-logo-base'

export function BaseHeader() {
  return (
    <Box mt="6" ml="7">
      <AppressaLogoBase width="200" height="32" />
    </Box>
  )
}
