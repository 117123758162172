import * as React from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import { useImpressionMade } from '../../../utils/use-impression-made'
import { handleProductClicked } from '../../../utils/handle-product-clicked'
import { ProductT } from '../../../clients/cms/index'
import { DesktopView } from './desktop-view'
import { MobileView } from './mobile-view'
import { productDetailsPhoneNumber } from './product-details-phone-number'

type ProductItemProps = {
  partnerColor: string
  partner: string
  product: ProductT
  shouldStartOpen: boolean
}

export function ProductItem(props: ProductItemProps) {
  const { product, partner } = props
  const showDeskTopView = useBreakpointValue({ lg: true })
  const [isCollapsed, setIsCollapsed] = React.useState(!props.shouldStartOpen)
  const handleSetCollapsed = () => {
    setIsCollapsed(!isCollapsed)
  }

  const orderId = useImpressionMade({ product })

  const handleApplyNow = () =>
    window.open(
      `/secure/${props.product.slug.current}?order_id=${orderId}`,
      '_ blank',
    )

  React.useEffect(() => {
    const { phoneNumber, phoneNumberVerbiage } = product

    if (phoneNumber) {
      props.product.marketingBullets.unshift(
        productDetailsPhoneNumber(phoneNumber, phoneNumberVerbiage),
      )
    }
  }, [props.product])

  return showDeskTopView ? (
    <DesktopView
      partnerColor={props.partnerColor}
      isCollapsed={isCollapsed}
      handleClick={() => {
        handleSetCollapsed()
        handleProductClicked({ product, partner })
      }}
      product={props.product}
      handleApplyNow={handleApplyNow}
    />
  ) : (
    <MobileView
      partnerColor={props.partnerColor}
      isCollapsed={isCollapsed}
      handleClick={() => {
        handleSetCollapsed()
        handleProductClicked({ product, partner })
      }}
      product={props.product}
      handleApplyNow={handleApplyNow}
    />
  )
}
