import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { ColorSerializer } from './color-serializer'
import { SanityTextWithColorProps } from './type'

export const SanityTextWithColor: React.FC<SanityTextWithColorProps> = ({
  blocks,
}) => (
  <BlockContent
    blocks={blocks}
    serializers={{
      marks: {
        color: ColorSerializer,
      },
    }}
  />
)
