import * as React from 'react'
import { Box, Container } from '@chakra-ui/react'
import { PRIMARY_COLOR } from '../colors'
import { FOOTER_ITEMS } from './config'
import FooterAppressaLogo from './footer-appressa-logo'
import FooterLinkItem from './footer-link-item'

type FooterProps = {
  backgroundColor?: string
  minHeight?: number
}

const DEFAULT_FOOTER_PROPS = Object.freeze({
  backgroundColor: PRIMARY_COLOR,
  minHeight: 86,
})

export function Footer(props: FooterProps) {
  const mergedProps = { ...DEFAULT_FOOTER_PROPS, ...props }
  return (
    <Box data-cy="footer" as="footer" bg={mergedProps.backgroundColor}>
      <Container
        maxW="160ch"
        minH={mergedProps.minHeight}
        display="flex"
        alignItems="center"
        paddingTop={{ base: 4, md: 0 }}
        paddingBottom={{ base: 4, md: 0 }}
      >
        <Box
          as="ul"
          flex={1}
          display="flex"
          justifyContent="right"
          flexDirection={{
            base: 'column',
            lg: 'row',
          }}
          alignItems={{
            base: 'center',
            lg: 'flex-end',
          }}
        >
          <FooterAppressaLogo />

          {FOOTER_ITEMS.map((item) => (
            <FooterLinkItem key={item.text} href={item.href} text={item.text} />
          ))}
        </Box>
      </Container>
    </Box>
  )
}
