const PRIMARY_COLOR = '#000A4C'
const PRIMARY_LINK_COLOR = '#28AAE2'
const SECONDARY_TEXT_COLOR = 'rgba(13, 36, 60, 0.4)'
const PRIMARY_BLUE_COLOR = '#29AAE2'

export {
  PRIMARY_COLOR,
  PRIMARY_LINK_COLOR,
  SECONDARY_TEXT_COLOR,
  PRIMARY_BLUE_COLOR,
}
