import React from 'react'

import BlockContent from '@sanity/block-content-to-react'
import { PRIMARY_COLOR } from '../../colors'
import { SanityTermsConditionsRendererProps, BlockContentProps } from './type'

/**
 *
 * Customized default serializer for Sanity block-type
 * https://github.com/sanity-io/block-content-to-react#customizing-the-default-serializer-for-block-type
 */
export const SanityTermsConditionsRenderer = (
  props: SanityTermsConditionsRendererProps,
): JSX.Element => {
  const { style } = props.node

  if (style === 'normal') {
    return React.createElement(
      'p',
      { style: { marginBottom: '1rem' } },
      props.children,
    )
  }

  if (style === 'sectionHeader') {
    return React.createElement(
      'h2',
      {
        style: { color: PRIMARY_COLOR, fontSize: '1.5rem', marginTop: '1rem' },
      },
      props.children,
    )
  }

  // Fall back to default handling.
  // This is a hack until @sanity/block-content-to-react adds typescript support.
  // See issue: https://github.com/sanity-io/block-content-to-react/issues/26
  return (BlockContent as BlockContentProps).defaultSerializers.types.block(
    props,
  ) as JSX.Element
}
