import { handleError } from './handle-error'

const URL = '/api/analytics/session-registered'

export async function handleSessionRegistered() {
  return fetch(URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  }).catch(handleError)
}
