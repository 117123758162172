import { z } from 'zod'

export const ZUserSession = z.object({
  userId: z.string(),
  lastVisited: z.string(),
  referringPartner: z.string(),
  partnerUserId: z.string(),
  referringUrl: z.string(),
  queryParamsRaw: z.string(),
})

export type UserSession = z.infer<typeof ZUserSession>
