import React from 'react'
import { handleSessionRegistered } from '../../utils/handle-session-registered'

/**
 * Handle Send Session Registered event
 */
export const OfferSessionHandler: React.FC<{
  children: React.ReactElement
}> = ({ children }) => {
  React.useEffect(() => {
    void handleSessionRegistered()
  }, [])

  return <div>{children}</div>
}
