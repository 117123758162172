import { useEffect, useState } from 'react'
import { ProductT } from '../clients/cms'
import { generateUniqueUserId } from '../components/offer-session-handler/generate-unique-user-id'
import { handleError } from './handle-error'

const URL = '/api/analytics/impression-made'
type UseImpressionMadeInput = {
  product: ProductT
  orderId: string
}

export function useImpressionMade(input: { product: ProductT }): string {
  const [orderId] = useState(generateUniqueUserId())

  useEffect(() => {
    fetch(URL, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(
        prepareBody({
          orderId,
          product: input.product,
        }),
      ),
    }).catch(handleError)
  }, [input.product.slug.current])
  return orderId
}

function prepareBody(input: UseImpressionMadeInput) {
  return {
    orderId: input.orderId,
    product: {
      id: input.product.slug.current,
      name: input.product.name,
    },
  }
}
