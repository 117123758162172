import { Button } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

type ProductDetailsButtonProps = {
  handleClick: () => void
  isCollapsed: boolean
}

export const ProductDetailsButton: React.FC<ProductDetailsButtonProps> = ({
  handleClick,
  isCollapsed,
}) => (
  <Button
    data-cy="product-details-button"
    py="2"
    color="brand.400"
    opacity="40%"
    bgColor="white"
    fontWeight="bold"
    fontSize={{
      base: 'small',
      md: '12px',
    }}
    _hover={{ bgColor: 'blackAlpha.40', color: 'black', opacity: 0.9 }}
    _active={{ bgColor: 'blackAlpha.40', color: 'black', opacity: 0.9 }}
    borderTopRadius="none"
    borderTopColor="gray.200"
    borderTopWidth="1px"
    width="100%"
    onClick={handleClick}
  >
    {isCollapsed ? 'Expand Details' : 'Show Less'}
    {isCollapsed ? (
      <ChevronDownIcon ml="1" boxSize="5" />
    ) : (
      <ChevronUpIcon ml="1" boxSize="5" />
    )}
  </Button>
)
