import { Box } from '@chakra-ui/layout'
import React from 'react'
import Link from 'next/link'
import styles from './footer-animation.module.css'

type FooterLinkItemProps = {
  text: string
  href: string
}

const FooterLinkItem: React.FC<FooterLinkItemProps> = ({ href, text }) => (
  <Box marginRight="4" as="li" listStyleType="none">
    <Link href={href} passHref>
      <Box
        color="white"
        fontSize="large"
        opacity={0.5}
        as="a"
        lineHeight="50px"
        className={styles['footer-item-hover']}
      >
        {text}
      </Box>
    </Link>
  </Box>
)

export default FooterLinkItem
