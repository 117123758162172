import React from 'react'
import { z } from 'zod'
import BlockContent from '@sanity/block-content-to-react'
import { SanityTermsConditionsRenderer } from '../terms-conditions/terms-conditions-renderer'
import Block from '../../../clients/cms/sanity/schema/block'
import { SanityLinkSerializer } from '../link-serializer'
import { SanityListSerializer } from '../list-serializer'

const Blocks = z.array(Block)

export type RenderBlockContentProps = {
  blocks: z.infer<typeof Blocks>
  SerializersBlock?: JSX.Element
  SerializersList?: JSX.Element
  SerializersMarksLink?: JSX.Element
}

export const RenderBlockContent: React.FC<RenderBlockContentProps> = ({
  blocks,
  SerializersBlock = SanityTermsConditionsRenderer,
  SerializersList = SanityListSerializer,
  SerializersMarksLink = SanityLinkSerializer,
}) => (
  <BlockContent
    blocks={blocks}
    serializers={{
      types: {
        block: SerializersBlock,
      },
      list: SerializersList,
      marks: {
        link: SerializersMarksLink,
        superscript: ({ children }: { children: React.ReactElement }) => {
          return <sup>{children}</sup>
        },
      },
    }}
  />
)
