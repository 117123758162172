import * as React from 'react'

import { ListItem, UnorderedList } from '@chakra-ui/react'
import { ProductT } from '../../clients/cms'
import { ProductItem } from './item/index'

type ProductListProps = {
  partner: string
  partnerColor: string
  products: ProductT[]
}

export function ProductList({
  partner,
  products,
  partnerColor,
}: ProductListProps) {
  return (
    <UnorderedList
      data-cy="product-list"
      styleType="none"
      marginInlineStart="0"
      marginX="auto"
      maxW={['sm', 'md', 'none']}
    >
      {products.map((product) => (
        <ListItem key={product.slug.current}>
          <ProductItem
            partner={partner}
            partnerColor={partnerColor}
            shouldStartOpen={products.length === 1}
            product={product}
          />
        </ListItem>
      ))}
    </UnorderedList>
  )
}
